import React, { useRef } from "react";
import { graphql, navigate } from "gatsby";

import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import NewsCard from "../components/Cards/NewsCard";
import Pagination from "../components/Global/Pagination";
import PropTypes from "prop-types";

// ====

function trimSlash(path) {
    return path.replace(/\/$/, "");
}

const News = ({ blok, data, pageContext, location }) => {
    let { numPages, currentPage, homepage } = pageContext || 0;

    let doc;
    let pageSlug;
    let items;
    if (data) {
        //    doc = JSON.parse(data.index.content);
        doc = JSON.parse(pageContext.story.content);
        pageSlug = trimSlash(pageContext.story.full_slug);
        items = data.news;
    } else {
        doc = blok;
        items = {
            nodes: [
                {
                    full_slug: "news/gaming/featured-news",
                    field_date_string: "2021-09-16 00:00",
                    content:
                        '{"title":"Article one", "preview":"Super important news here", "image":null}',
                    parent_id: null,
                },
                {
                    full_slug: "news/gaming/featured-news",
                    field_date_string: "2021-09-16 00:00",
                    content:
                        '{"title":"Article one", "preview":"Super important news here", "image":null}',
                    parent_id: null,
                },
                {
                    full_slug: "news/gaming/featured-news",
                    field_date_string: "2021-09-16 00:00",
                    content:
                        '{"title":"Article one", "preview":"Super important news here", "image":null}',
                    parent_id: null,
                },
                {
                    full_slug: "news/gaming/featured-news",
                    field_date_string: "2021-09-16 00:00",
                    content:
                        '{"title":"Article one", "preview":"Super important news here", "image":null}',
                    parent_id: null,
                },
            ],
        };
    }
    const openGraph = {
        type: "website",
        title: `${homepage ? doc.title : doc.pagination_title}`,
        description: `${homepage ? doc.body : doc.pagination_body}`,
        image: (doc.meta_image?.filename && doc.meta_image) || doc.image,
    };

    const pageTitle = homepage ? doc.title : doc.pagination_title;

    const pathname = location.pathname ? location.pathname : "";

    const selectRef = useRef(null);
    function selectionValue() {
        navigate(selectRef.current.value);
    }

    return (
        <Layout
            title={pageTitle && pageTitle}
            colour={doc.colour ? doc.colour : "black"}
            image={doc.image && doc.image}
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <section className="mb-3 mb-md-5">
                <div className="container">
                    {homepage && (
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="brand-background-white p-4 text-end">
                                    <select
                                        className="form-select w-auto ms-auto"
                                        aria-label="News filter"
                                        ref={selectRef}
                                        defaultValue={pathname}
                                        onChange={() => selectionValue()}
                                    >
                                        <option hidden>Filter</option>
                                        <option value="/blog/">
                                            All posts
                                        </option>
                                        <option value="/blog/company/">
                                            Company
                                        </option>
                                        <option value="/blog/games/">
                                            Games
                                        </option>
                                        <option value="/blog/people/">
                                            People
                                        </option>
                                        <option value="/blog/recruitment/">
                                            Recruitment
                                        </option>
                                        <option value="/blog/tech/">
                                            Tech
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row gy-4">
                        {items &&
                            items.nodes.map((node, index) => {
                                const news = JSON.parse(node.content);
                                let size = "3";

                                if (homepage && index === 0) {
                                    size = "6";
                                }
                                if (
                                    (homepage && index === 8) ||
                                    (homepage && index === 10) ||
                                    (homepage && index === 11)
                                ) {
                                    size = "6";
                                }

                                return (
                                    <div
                                        key={news._uid}
                                        className={`col-12 col-lg-${size}`}
                                    >
                                        <NewsCard
                                            item={node}
                                            layout={
                                                homepage ? "full" : "compact"
                                            }
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>

            {numPages && numPages > 1 && (
                <Pagination
                    numPages={numPages}
                    currentPage={currentPage}
                    paginationPrefix={doc.pagination_prefix}
                    homepage={pageSlug}
                />
            )}
        </Layout>
    );
};

export default News;

export const QUERY = graphql`
    query NewsIndexDefaultQuery($skip: Int!, $limit: Int!, $parent_id: [Int]) {
        # Query the data from the index page
        index: storyblokEntry(
            field_component: { eq: "news" }
            lang: { eq: "default" }
        ) {
            content
        }

        # Grab news articles
        news: allStoryblokEntry(
            filter: {
                field_component: { eq: "news_post" }
                parent_id: { in: $parent_id }
            }
            sort: {
                fields: [field_featured_boolean, field_date_string]
                order: [DESC, DESC]
            }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                full_slug
                field_date_string
                content
                parent_id
            }
        }
    }
`;

News.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
    data: PropTypes.object,
};

News.defaultProps = {
    pageContext: null,
    blok: null,
    data: null,
};
